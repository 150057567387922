import axios from 'axios';

export class AuthService {

  login = (username, password, client) => {
    const body = new URLSearchParams();
    body.append("grant_type", "password")
    body.append("username", username)
    body.append("password", password)
    body.append("clientKey", client)
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/token`, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
        password: process.env.REACT_APP_BASIC_AUTH_PASSWORD
      }
    })
  }

  signUp = (user) => {
    // Codigo para probar componente para crear usuario
    // return new Promise((res, rej) => {
    //   setTimeout(() => {
    //     res("ok")
    //   }, 500);
    // })
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/activate-user`,
      user,
      {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
        }
      }
    )
  }

  sendEmailPassword = (email, clientId) => {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/send-email-restore-password?client=${clientId}`,
      {
        email: email.email
      }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  restorePassword = (uuid, password) => {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/restore-password/${uuid}`,
      {
        password: password
      }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  refreshToken = async (token) => {
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/token?grant_type=token&refresh_token=${token}`, {
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  verifySSO = (clientKey) => axios.get(`${process.env.REACT_APP_API_BASE_URL}/public/sso/${clientKey}/verify`,
    {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    }
  )
}
